import Router, { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { AuthType } from 'types/Auth'

const useAuthType = () => {
  const router = useRouter()
  const authState = useState<AuthType>(() => {
    return (router.query.authType || 'password') as AuthType
  })

  const [authType] = authState
  useEffect(() => {
    Router.replace(
      {
        pathname: Router.pathname,
        query: {
          ...(Router.query || {}),
          authType,
        },
      },
      undefined,
      { shallow: true },
    )
  }, [authType])

  return authState
}

export default useAuthType
