import {
  signInWithPopup,
  AuthError,
  GoogleAuthProvider,
  TwitterAuthProvider,
  AuthProvider,
  OAuthProvider,
} from 'firebase/auth'
import { firebaseAuth } from 'lib/firebase'
import handleExistingAccount from 'views/authentication/services/handleExistingAccount'

const authenticateWithProvider = async (provider: AuthProvider, isMobile: boolean) => {
  try {
    const userCredential = isMobile
      ? await signInWithPopup(firebaseAuth, provider) // switch to signInWithPopup on both mobile browsers and desktop browsers
      : await signInWithPopup(firebaseAuth, provider)

    return userCredential
  } catch (error) {
    return await handleExistingAccount(error as unknown as AuthError, true)
  }
}

export const authenticateWithApple = async (isMobile: boolean) => {
  const provider = new OAuthProvider('apple.com')
  provider.addScope('email')
  provider.addScope('name')
  const userCredential = await authenticateWithProvider(provider, isMobile)
  return userCredential
}

export const authenticateWithGoogle = async (isMobile: boolean) => {
  const provider = new GoogleAuthProvider()
  provider.addScope('https://www.googleapis.com/auth/userinfo.profile')
  const userCredential = await authenticateWithProvider(provider, isMobile)
  return userCredential
}

export const authenticateWithTwitter = async (isMobile: boolean) => {
  const provider = new TwitterAuthProvider()
  const userCredential = await authenticateWithProvider(provider, isMobile)
  return userCredential
}
