import { trimStringProps } from 'shared/utils/string'
import { NewBackEndApiResponseData } from 'types/ApiResponseData'
import { apiClient } from 'utils/apiClient'

const signUpWithEmailAndPassword = async (args: {
  email: string
  password: string
  firstName: string
  lastName?: string
  isArtist?: boolean
  referralCode?: string
  asRole?: 'creator' | 'independent-advisor' // API now only accepts 'creator' or 'independent-advisor'
}) => {
  const { email, password, firstName, isArtist, referralCode, lastName = '', asRole } = args
  const submitData = {
    email: email.toLowerCase(),
    password,
    signupType: 'web_signup',
    firstName,
    lastName,
    isArtist,
    role: asRole,
    referralCode: referralCode ? referralCode.trim().toLowerCase() : undefined,
    noOTP: true,
  }

  try {
    const response = await apiClient.post(
      '/v2/auth/sign-up-new',
      trimStringProps(submitData, ['email', 'firstName', 'lastName']),
    )

    const { ok, data, message } = response.data as NewBackEndApiResponseData<{ isEmailVerified?: boolean }>
    const success = (() => {
      // new user
      if (ok) return true
      // signed up but not verified email
      if (!ok && data?.isEmailVerified === false) return true
      // return false for other cases
      return false
    })()

    if (success) return true // do nothing if success (page will redirect to verify email page)
    // if failed, throw error
    throw message
  } catch (error) {
    const errorMessage = typeof error === 'string' ? error : 'Something went wrong, please try again later'
    throw errorMessage
  }
}

export default signUpWithEmailAndPassword
