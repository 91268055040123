import { authUserSwrKey } from 'constants/swrKeys'
import { mutate } from 'swr'
import { UserProfile } from 'types/V3/User'

type LoginUserOptions = {
  revalidate?: boolean
}

// this update the current user in the swr, marks the user as authenticated
const loginUser = async (data: UserProfile, options: LoginUserOptions = {}) => {
  const { revalidate } = options
  const user = revalidate
    ? await mutate<UserProfile>(authUserSwrKey) // this triggers a revalidation, since we may change the user details in the confirm invitation API call
    : await mutate<UserProfile>(authUserSwrKey, data, { revalidate: false })

  if (!user) throw new Error('Cannot login user')
}

export default loginUser
